import React from 'react';
import { observer } from 'mobx-react';
import App from './../stores/App'
import Chat from './../stores/Chat'
import locale from './../locale/locale'

const Nav = observer(
	class Nav extends React.Component {
		
		render() {
			return (
        <header className={`clip__chat--nav ${(App.selected_channel !== null && App.selected_tab === "q&a") || (App.selected_tab === "directory" && Chat.show_direct_messages()) ? "no-shadow" : ""}`}>
          {
            !Chat.is_init_loading() ?
            <>
            {
              Chat.show_directory() ?
              <button onClick={() => App.set_tab("directory")} className={App.selected_tab === "directory" ? "clip_chat-nav-directory selected" : "clip_chat-nav-directory"}>
                <svg width="30" enableBackground="new 0 0 48 48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m47 1v46h-46v-46zm1-1h-48v48h48z" fill="#fff" opacity="0"></path><g fill="none"><path d="m32.1556 34.9825h-16.1556" stroke="#2dd5c9" strokeMiterlimit="10" strokeWidth="2.0911"></path><path d="m29 32v-8.1327c0-1.5835-1.3776-2.8673-3.0769-2.8673h-3.8462c-1.6993 0-3.0769 1.2838-3.0769 2.8673v8.1327" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"></path><path d="m21.7708 12.985v2.0301c0 1.0946 1.0007 1.985 2.2297 1.985s2.2287-.8904 2.2287-1.985v-2.0301c0-1.0946-.9997-1.985-2.2287-1.985s-2.2297.8904-2.2297 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"></path><path d="m37.25 14.985v2.0301c0 1.0946 1.01 1.985 2.2505 1.985s2.2495-.8904 2.2495-1.985v-2.0301c0-1.0946-1.009-1.985-2.2495-1.985s-2.2505.8904-2.2505 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.1008"></path><path d="m6.2708 14.985v2.0301c0 1.0946 1.0007 1.985 2.2297 1.985s2.2287-.8904 2.2287-1.985v-2.0301c0-1.0946-.9997-1.985-2.2287-1.985s-2.2297.8904-2.2297 1.985z" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"></path><path d="m44 36.0083v-10.0716c0-1.6173-1.3521-2.9284-3.0201-2.9284h-2.9599c-1.6679 0-3.02 1.3744-3.02 2.9917v10.0083" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"></path><path d="m0 0h48v48h-48z" opacity="0"></path><path d="m13 36.0083v-10.0716c0-1.6173-1.3521-2.9284-3.0201-2.9284h-2.9598c-1.668 0-3.0201 1.3744-3.0201 2.9917v10.0083" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.0911"></path></g></svg>
              </button>
              : null
            }
            {
              Chat.show_event_chat() && Chat.channel_by_id("event") ?
              <button onClick={() => App.set_tab("event")} className={App.selected_tab === "event" ? "selected" : ""}>
                {Chat.channel_by_id("event").name}
              </button>
              : null
            }
            {
              Chat.show_qa_chat() && Chat.channel_by_id("q&a") ?
              <button onClick={() => App.set_tab("q&a")} className={App.selected_tab === "q&a" ? "selected" : ""}>
                {Chat.channel_by_id("q&a").name}
              </button>
              : null
            }
            </>
            : 
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                {locale.loading}
              </span>
          }
          {/* <button className="clip__chat--close" onClick={App.send_chat_close}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{width: 24, height: 24}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button> */}
        </header>
			)
		}
	}
)
export default Nav