import React from 'react';
import { observer } from 'mobx-react';
import Picker from 'emoji-picker-react';
import Chat from '../stores/Chat'
import locale from './../locale/locale'

const Input = observer(
	class Input extends React.Component {

		constructor (props) {
			super(props);
			this.state = {
				emoji_picker_visible: false,
			}
			this._input_ref = React.createRef()
		}

		componentDidMount = () => {
			Chat.set_current_input_ref(this._input_ref)
		}

		check_for_enter = (e) => {
			if (e.key === "Enter" && !this.props.channel.is_sending_message) {
				this.props.channel.send_message_text()
			}
		}

		handle_emoji = (object) => {
			this.props.channel.handle_emoji_input(object)
			this.setState({
				emoji_picker_visible: false,
			})
			if (this._input_ref.current) {
				setTimeout(() => {
					this._input_ref.current.focus()
				}, 100)
			}
		}
		
		render() {
			if (this.props.channel == null) {
				return null
			}
			return (
				<>
					{
						this.props.channel.is_typing_reply && this.props.channel.reply_message &&
						<div className="clip__chat--input--reply">
							<div className="clip__chat--input--reply-actions">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" style={{ width: 18, height: 18}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
									</svg>
									{locale.replying_to}
								</span>
								<button onClick={this.props.channel.cancel_reply_message}>
									{locale.cancel}
									<svg xmlns="http://www.w3.org/2000/svg" style={{width: 18, height: 18}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</button>
							</div>
							
							<div className="clip__chat--input--reply-message">
								<div className="clip__chat--message-info">
									<span>{this.props.channel.reply_message.full_name()}</span>
									<time>{this.props.channel.reply_message.ago(this.state.counter)}</time>
								</div>
								<div>{this.props.channel.reply_message.text}</div>
							</div>
							
						</div>
					}
					<div className="clip__chat--input">
						<div className="clip__chat--input__emoji">
							<button className="clip__chat--input__emoji--trigger" onClick={() => this.setState({ emoji_picker_visible: !this.state.emoji_picker_visible })}>
								<span role="img" aria-label="smile emoji">😀</span>
							</button>
							{
								this.state.emoji_picker_visible && <Picker onEmojiClick={(e, emojiObject) => this.handle_emoji(emojiObject)} disableSkinTonePicker={true} disableAutoFocus={true} native={true} disableSearchBar />
							}
						</div>
						<input disabled={this.props.channel.is_sending_message} ref={this._input_ref} onKeyPress={this.check_for_enter} autoFocus type="text" value={this.props.channel.text} placeholder={this.props.channel.is_typing_reply ? locale.enter_reply : locale.type_something} onChange={(event) => this.props.channel.handle_text_input(event.target.value)} />
						<button className="clip__chat--input__button" onClick={this.props.channel.send_message_text} disabled={this.props.channel.text === "" || this.props.channel.text.length === 0 || this.props.channel.is_sending_message}>
							{
								this.props.channel.is_sending_message ?
									<svg xmlns="http://www.w3.org/2000/svg" style={{width: 20, height: 20}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								:
								this.props.channel.is_typing_reply && this.props.channel.reply_message ?	
								<svg xmlns="http://www.w3.org/2000/svg" style={{ width: 20, height: 20}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
								</svg>
								:
								<svg xmlns="http://www.w3.org/2000/svg" style={{width: 20, height: 20}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M9 5l7 7-7 7" />
								</svg>
							}
						</button>
					</div>
				</>			
			)
		}
	}
)
export default Input