import axios from 'axios';
import App from '../stores/App';
import Chat from '../stores/Chat';
import Logger from '../stores/Logger';

export const API_URL = "https://europe-central2-parasol-xpc.cloudfunctions.net"
export const ERROR = 1
export const NOT_VERIFIED = 2
export const MESSAGE_SENT = 3
export const MESSAGE_DELETED = 4

class XPCApi {

	async verify_token() {
		Logger.log('XPCApi:verify_token', App.jwt_token, Chat.uid);
		const data = axios
			.post(`${API_URL}/verifyToken`, {
        anonymous_uid: Chat.uid
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:verify_token:data', response.data);
        if(response.data?.signature_verified === true){
          return response.data
        }
        else{
          return NOT_VERIFIED
        }
			})
			.catch(error => {
				Logger.warn('XPCApi:verify_token:error', error);
				return ERROR
			});
		return data;
	}
  
  async send_message(text, channel_id = null, reply_message_uid = null, local_id = null) {
		Logger.log('XPCApi:send_message', App.jwt_token, text, channel_id, local_id);
		const data = axios
			.post(`${API_URL}/createMessage`, {
        message: {
          text: text,
          local_id: local_id
        },
        channel_uid: channel_id,
        message_uid: reply_message_uid
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:send_message:data', response.data);
        return MESSAGE_SENT
			})
			.catch(error => {
				Logger.warn('XPCApi:send_message:error', error);
				return ERROR
			});
		return data;
  }
  
  async send_reaction(type, message_id, channel_id = null) {
		Logger.log('XPCApi:send_reaction', App.jwt_token, type, channel_id);
		const data = axios
			.post(`${API_URL}/messageReaction`, {
        reaction: {
          type: type
        },
        channel_uid: channel_id,
        message_uid: message_id
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:send_reaction:data', response.data);
        return MESSAGE_SENT
			})
			.catch(error => {
				Logger.warn('XPCApi:send_message:error', error);
				return ERROR
			});
		return data;
  }
  
  async delete_message(message_id, channel_id = null) {
		Logger.log('XPCApi:delete_message', App.jwt_token, channel_id);
		const data = axios
			.post(`${API_URL}/messageDelete`, {
        channel_uid: channel_id,
        message_uid: message_id
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:delete_message:data', response.data);
        return MESSAGE_DELETED
			})
			.catch(error => {
				Logger.warn('XPCApi:delete_message:error', error);
				return ERROR
			});
		return data;
  }
  
  async create_direct_message(directory_uid) {
		Logger.log('XPCApi:create_direct_message', App.jwt_token, directory_uid);
		const data = axios
			.post(`${API_URL}/getDirectChannel`, {
        directory_uid: directory_uid
      }, {
        headers:{
          Authorization : `Bearer ${App.jwt_token}`,
          "Content-Type": "application/json; charset=utf-8"
        }
      })
			.then(response => {
        Logger.log('XPCApi:create_direct_message:data', response.data);
        return response.data
			})
			.catch(error => {
				Logger.warn('XPCApi:create_direct_message:error', error);
				return ERROR
			});
		return data;
  }

}

export default new XPCApi()