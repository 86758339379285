import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { types } from 'mobx-state-tree';
dayjs.extend(relativeTime)

const ThreadMessage = types
	.model('ThreadMessage', {
		message_uid: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    profile_photo_url: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    wp_uid: types.maybeNull(types.number),
    created_at: types.maybeNull(types.Date),
    updated_at: types.maybeNull(types.Date)
	})
  .views(self => ({
    
    full_name(){
      return `${self.first_name} ${self.last_name}`
    },
    
    ago(){
      return self.created_at != null ? dayjs().to(self.created_at) : ''
    },

    is_moderator() {
      return self.role === 'moderator'
    }
    
  }))
export default ThreadMessage;
