import React from 'react';
import { observer } from 'mobx-react';
import locale from '../locale/locale';

const LoadingModule = observer(
	class LoadingModule extends React.Component {
		
		render() {
      return(
        <div className="clip__chat--loading">
          <h3>{this.props.title ? this.props.title : locale.loading}</h3>
          {
            this.props.subtitle ?
            <p>{this.props.subtitle}</p>
            : null
          }
        </div>
      )
		}
    
	}
)
export default LoadingModule