/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger';
import PreFlight from './PreFlight';
import Chat from './Chat';

let CONTAINER_ELEMENT = null
const SIDEBAR_CHAT_CONTROL = document.querySelector('.main-sidebar-controls')?.querySelector('.control-messages')

const App = types
	.model('App', {
		is_loading: types.optional(types.boolean, false),
		error: types.optional(types.string, ""),
		debug_enabled: types.optional(types.boolean, false),
		jwt_token: types.maybeNull(types.string),
		chat_did_load: types.optional(types.boolean, false),
		selected_tab: types.optional(types.string, "event"),
		did_specify_tab: types.optional(types.boolean, false),
		selected_channel: types.maybeNull(types.string),
		directory_search_term: types.optional(types.string, ""),
		selected_direct_message_channel: types.maybeNull(types.string),
		selected_directory_tab: types.optional(types.string, "directory"),
		qa_search_term: types.optional(types.string, ""),
		notifications_enabled: types.optional(types.boolean, false),
	})
	.actions(self => ({

		hydrate: flow(function* (element) {
			CONTAINER_ELEMENT = element
			if(element?.getAttribute("data-enable-debug") !== null){
				self.debug_enabled = true
			}
			Logger.log("App:hydrate", CONTAINER_ELEMENT, SIDEBAR_CHAT_CONTROL)
			const stop_loading = yield App.load_for_enabled_users_only()
			if(stop_loading){
				return
			}
			self.is_loading = true
			
			yield App.load_and_set_element_attributes()
			App.clean_up_dom()
			
			// We want to check if we have a JWT token
			if(self.jwt_token){
				// Do PreFlight checks
				const is_valid_preflight = yield PreFlight.init()
				if(is_valid_preflight && App.nice_iss_token() != null){
					// Start loading chats and all that good stuff
					yield Chat.hydrate()
					if(SIDEBAR_CHAT_CONTROL != null && self.can_show_clip()){
						SIDEBAR_CHAT_CONTROL.classList.remove('hidden')
					}
					else{
						App.do_nothing("PreFlight was OK, but couldn't load Chat!")
					}
					setTimeout(() => {
						App.update_selected_tab_after_hydrate()
					}, 250)
				}
			}
			self.set_is_loading(false)
			setTimeout(() => {
				App.toggle_notifications()
			}, 2000)
		}),
		
		load_for_enabled_users_only: flow(function* () {
			Logger.log("App:load_for_enabled_users_only")
			if(CONTAINER_ELEMENT?.getAttribute("data-active-user-only") !== null){
				const is_active = document.querySelector('.user-active');
				Logger.log("App:hydrate:user_is_active_class", is_active)
				if(!is_active){
					// Check if we are inactive first
					const is_inactive = document.querySelector('.user-inactive')
					if(is_inactive){
						App.set_error_message("No user is active yet... Waiting")
						setTimeout(() => {
							App.hydrate(CONTAINER_ELEMENT)
						}, 5000)
					}
					else{
						App.set_error_message("No user is active")
						setTimeout(() => {
							App.hydrate(CONTAINER_ELEMENT)
						}, 5000)
					}
					return true
				}
			}
			return false
		}),
		
		load_and_set_element_attributes: flow(function* () {
			Logger.log("App:load_and_set_element_attributes", CONTAINER_ELEMENT)
			self.jwt_token = self.debug_enabled && process.env.NODE_ENV === 'development' && CONTAINER_ELEMENT?.getAttribute("data-debug-jwt-token") ? CONTAINER_ELEMENT?.getAttribute("data-debug-jwt-token") : window.getCookie('token')
			if (CONTAINER_ELEMENT?.getAttribute("data-selected-tab") !== null) {
				self.selected_tab = CONTAINER_ELEMENT?.getAttribute("data-selected-tab")
				self.did_specify_tab = true
			}
			Logger.log("App:load_and_set_element_attributes:set", self)
			if(self.jwt_token === null){
				App.set_error_message("No token was provided")
				return false
			}
			return true
		}),
		
		clean_up_dom: flow(function* () {
			Logger.log("App:cleanup_dom", CONTAINER_ELEMENT)
			if(!App.debug_enabled){
				CONTAINER_ELEMENT.removeAttribute('data-active-user-only')
				CONTAINER_ELEMENT.removeAttribute('data-enable-debug')
				CONTAINER_ELEMENT.removeAttribute('data-debug-jwt-token')
				CONTAINER_ELEMENT.removeAttribute("data-selected-tab")
			}
			CONTAINER_ELEMENT.setAttribute('data-module-did-init', "true")
		}),

		set_is_loading: flow(function* (loading) {
			Logger.log("App:set_is_loading", loading)
			self.is_loading = loading
		}),
		
		set_error_message: flow(function* (message) {
			Logger.log("App:set_error_message", message)
			self.error = message
		}),
		
		do_nothing: flow(function* (message) {
			// Do nothing. Keep everything hidden and don't process anything else.
			// Let's just log it, so you can see it in debug mode.
			App.set_error_message(message)
		}),
    
    update_selected_tab: flow(function* () {
			Logger.log("App:update_selected_tab")
      if(!Chat.show_directory() && self.selected_tab === "directory" && (Chat.show_event_chat() || Chat.show_qa_chat())){
        self.selected_tab = Chat.show_event_chat() ? "event" : Chat.show_qa_chat() ? "qa" : null
			}
		}),

		update_selected_tab_after_hydrate: flow(function* () {
			Logger.log("App:update_selected_tab")
			if (self.did_specify_tab) {
				return
			}
      if(!Chat.show_directory() && self.selected_tab === "directory" && (Chat.show_event_chat() || Chat.show_qa_chat())){
        self.selected_tab = Chat.show_event_chat() ? "event" : Chat.show_qa_chat() ? "qa" : null
			}
			else if(Chat.show_directory() && !Chat.show_event_chat()) {
				self.selected_tab = Chat.show_directory() && !Chat.show_event_chat() ? "directory" : Chat.show_event_chat() ? "event" : Chat.show_qa_chat() ? "qa" : null
			}
		}),
    
    set_tab: flow(function* (tab) {
			Logger.log("App:set_tab", tab)
      self.selected_tab = tab
		}),
    
    send_chat_close: flow(function* () {
			Logger.log("App:send_chat_close")
		}),

		set_selected_channel: flow(function* (channel = null) {
			Logger.log("App:set_selected_channel", channel)
      self.selected_channel = channel
		}),

		set_directory_search_term: flow(function* (term = "") {
			Logger.log("App:set_directory_search_term", term)
			self.directory_search_term = term
		}),

		set_directory_tab: flow(function* (tab) {
			Logger.log("App:set_directory_tab", tab)
      self.selected_directory_tab = tab
		}),

		set_selected_direct_message_channel: flow(function* (channel) {
			Logger.log("App:set_selected_direct_message_channel", channel)
      self.selected_direct_message_channel = channel
		}),

		set_qa_search_term: flow(function* (term = "") {
			Logger.log("App:set_qa_search_term", term)
			self.qa_search_term = term
		}),

		toggle_notifications: flow(function* (enabled = true) {
			Logger.log("App:toggle_notifications", enabled)
			self.notifications_enabled = enabled
		}),

		update_notifications: flow(function* (channel) {
			Logger.log("App:update_notifications", channel.id, self.notifications_enabled)
			if (!self.notifications_enabled) {
				return
			}
			// Handle q&a notifications
			if ((channel.type === "q&a" || channel.id === "q&a") && self.selected_tab === "q&a" && self.selected_channel !== channel.id) {
				Logger.log("App:update_notifications:q&a", channel.id, channel.type)
				channel.toggle_unread(true)
			}
		}),

	}))
	.views(self => ({
		
		parsed_jwt_token(){
			var base64_url = self.jwt_token.split('.')[1]
    	var base64 = base64_url.replace(/-/g, '+').replace(/_/g, '/')
    	var json_payload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    	}).join(''))
    	return JSON.parse(json_payload);
		},
		
		can_show_clip(){
			return PreFlight.did_pass_preflight
		},
		
		nice_iss_token(){
			return this.parsed_jwt_token()?.iss ? this.parsed_jwt_token()?.iss.replace("https://", "").replace("http://", "") : null
		}
		
	}))
	.create();

export default App;
