import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import Chat from './../stores/Chat'
import Input from './../components/input'
import Loading from './Loading'
import locale from '../locale/locale';
const Messages = lazy(() => import('./../components/messages'));

const EventChatModule = observer(
	class EventChatModule extends React.Component {
		
		render() {
      const channel = Chat.channel_by_id("event")
      return(
        <>
          <Suspense fallback={<Loading subtitle={locale.getting_messages} />}>
            <Messages channel={channel} />
          </Suspense>
          <Input channel={channel} />
        </>
      )
		}
    
	}
)
export default EventChatModule