/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger'
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, getDocs, query, onSnapshot, orderBy, where } from "firebase/firestore"; 
import XPCApi, { ERROR, NOT_VERIFIED } from '../api/XPCApi';
import App from './App';
import Profile from './models/Profile';
import Channel from './models/Channel';

export const app = initializeApp({
  apiKey: 'AIzaSyCnR4MAJv5odjnr2KBh3jzHGEnIgUwQd1M',
  authDomain: 'parasol-xpc.firebaseapp.com',
  databaseURL: 'https://parasol-xpc-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'parasol-xpc',
  storageBucket: 'parasol-xpc.appspot.com',
  messagingSenderId: '671138391297',
  appId: '1:671138391297:web:ce4a663fdd9c4786b3e700'
});

const auth = getAuth(app)
const database = getFirestore(app)
let main_db_document = null
let current_message_list_ref = null
let current_input_ref = null

export { auth, database }

const Chat = types
	.model('Chat', {
    uid: types.maybeNull(types.string),
    user: types.maybeNull(Profile),
    directory: types.optional(types.array(Profile), []),
    directory_active: types.optional(types.boolean, true),
    selected_profile: types.maybeNull(types.reference(Profile)),
    is_loading_directory: types.optional(types.boolean, true),
    channels: types.optional(types.array(Channel), []),
    is_loading_chats: types.optional(types.boolean, true),
    reactions: types.optional(types.array(types.model('Reaction', {
      type: types.maybeNull(types.string),
      emoji: types.maybeNull(types.string),
    })), [{type: 'love', emoji: '❤️'}, {type: 'thumbsup', emoji: '👍'}, {type: 'party', emoji: '🎉'}]),
	})
	.actions(self => ({

		init: flow(function* () {
			Logger.log("Chat:init")
      const anonymous_auth = yield signInAnonymously(auth)
      Logger.log("Chat:init:anonymous_auth", anonymous_auth)
      if(anonymous_auth && anonymous_auth?.user?.uid){
        Logger.log("Chat:init:anonymous_auth:uid", anonymous_auth.user.uid)
        self.uid = anonymous_auth.user.uid
        const verified = yield XPCApi.verify_token()
        if(verified && verified !== ERROR && verified !== NOT_VERIFIED){
          self.uid = verified.docRefId
          return true
        }
      }
      return false
		}),
    
    hydrate: flow(function* () {
      Logger.log("Chat:hydrate", App.nice_iss_token())
      try {
        main_db_document = doc(database, 'sites', App.nice_iss_token())
        const document_snapshot = yield getDoc(main_db_document)
        if(document_snapshot.exists()){
          Logger.log("Chat:hydrate:config", document_snapshot.data())
          if (document_snapshot.data().directory_active != null) {
            self.directory_active = document_snapshot.data().directory_active
          }
          // Load Directory
          yield Chat.hydrate_directory()
          // Load Chat data
          yield Chat.hydrate_chats()
        }
        return self.show_directory() || self.show_event_chat() || self.show_qa_chat()
      }
      catch (e) {
        setTimeout(() => {
          self.hydrate()
        }, 3000)
        return false
      }
		}),
    
    hydrate_directory: flow(function* () {
			Logger.log("Chat:hydrate_directory")
      self.is_loading_directory = true
      const directory_query = query(collection(main_db_document, 'directory'))
      const current_user = yield getDoc(query(doc(main_db_document, 'directory', self.uid)))
      Logger.log("Chat:hydrate_directory:query:current_user", current_user?.id)
      if (current_user?.id === self.uid) {
        self.user = {id: current_user.id, ...current_user.data()}
      }
      onSnapshot(directory_query, (doc) => {
        Chat.update_directory(doc.docChanges())
      })
      self.is_loading_directory = false
		}),
    
    update_directory: flow(function* (data) {
			Logger.log("Chat:update_directory", data.length)
      data.forEach((change) => {
        if(change.type !== "modified" && change.type !== "added"){
          return
        }
        const doc = change.doc
        Logger.log("Chat:update_directory:doc", doc.data())
        if(doc.id !== self.uid){
          const existing_profile = self.directory.find(p => p.id === doc.id)
          if(existing_profile){
            existing_profile.update({id: doc.id, ...doc.data()})
          }
          else{
            self.directory.push({id: doc.id, ...doc.data()})
          }
        }
        else if(doc.id === self.uid){
          self.user = {id: doc.id, ...doc.data()}
        }
      })
      return
		}),
    
    hydrate_chats: flow(function* () {
			Logger.log("Chat:hydrate_chats")
      self.is_loading_chats = true
      const snapshot = yield getDocs(query(collection(main_db_document, 'chat')))
      snapshot.forEach((doc) => {
        Logger.log("Chat:hydrate_chats:doc", doc.id, doc.data())
        if(doc.data()?.is_published || doc.id === "direct"){
          self.channels.push(Channel.create({id: doc.id, ...doc.data()}))
        }
      })
      self.is_loading_chats = false
		}),
    
    init_chat_by_model: flow(function* (model) {
			Logger.log("Chat:init_chat_by_model", model)
      if(model.id === "event"){
        const messages_query = query(collection(main_db_document, 'chat', model.id, 'messages'), orderBy("created_at"))
        onSnapshot(messages_query, (doc) => {
          model.handle_message_updates(doc.docChanges())
        })
      }
      else if (model.id === "q&a") {
        // We want to load different data depending if the user is a moderator or not
        const qa_query = self.is_current_user_moderator() ? query(collection(main_db_document, 'chat', model.id, 'channels')) : query(collection(main_db_document, 'chat', model.id, 'channels'), where("wp_uid", "==", self.user.wp_uid))
        onSnapshot(qa_query, (doc) => {
          Logger.log("Chat:onSnapshot:q&a:channels", doc.docChanges())
          doc.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              Chat.add_channel(Channel.create({id: change.doc.id, type: "q&a", ...change.doc.data()}))
            }
            else if (change.type === "removed") {
              Chat.remove_channel(change.doc.id)
            }
          })
        })
      }
      else if (model.type === "q&a") {
        const messages_query = query(collection(main_db_document, 'chat', model.type, 'channels', model.id , 'messages'), orderBy("created_at"))
        onSnapshot(messages_query, (doc) => {
          model.handle_message_updates(doc.docChanges())
        })
      }
      else if (model.id === "direct") {
        // We only want to load the messages for the current user
        const dm_query = query(collection(main_db_document, 'chat', model.id, 'channels'), where(`wp_uids.${self.user.wp_uid}`, "==", true))
        onSnapshot(dm_query, (doc) => {
          Logger.log("Chat:onSnapshot:direct:channels", doc.docChanges())
          doc.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              let data = change.doc.data()
              if (data.wp_uids) {
                let wp_uids = []
                Object.keys(data.wp_uids).forEach((key) => {
                  wp_uids.push({id: Number(key)})
                })
                data.wp_uids = wp_uids
              }
              Chat.add_channel(Channel.create({id: change.doc.id, type: "direct", ...data}))
            }
            else if (change.type === "removed") {
              Chat.remove_channel(change.doc.id)
            }
          })
        })
      }
      else if (model.type === "direct") {
        const messages_query = query(collection(main_db_document, 'chat', model.type, 'channels', model.id , 'messages'), orderBy("created_at"))
        onSnapshot(messages_query, (doc) => {
          model.handle_message_updates(doc.docChanges())
        })
      }
      return null
    }),
    
    add_channel: flow(function* (channel) {
      Logger.log("Chat:add_channel", channel)
      self.channels.push(channel)
    }),

    remove_channel: flow(function* (channel_id) {
      Logger.log("Chat:remove_channel", channel_id)
      self.channels = self.channels.filter(channel => channel.id !== channel_id)
    }),
    
    set_current_message_list_ref: flow(function* (ref) {
      Logger.log("Chat:set_current_message_list_ref", ref.current)
      current_message_list_ref = ref.current
    }),
    
    scroll_current_message_list_ref: flow(function* () {
      Logger.log("Chat:scroll_current_message_list_ref", current_message_list_ref)
      setTimeout(() => {
        if(current_message_list_ref !== null){
          current_message_list_ref.scrollTop = current_message_list_ref.scrollHeight
        }
      }, 100)
    }),

    set_current_input_ref: flow(function* (ref) {
      Logger.log("Chat:set_current_input_ref", ref.current)
      current_input_ref = ref.current
    }),

    focus_current_input_ref: flow(function* () {
      Logger.log("Chat:focus_current_input_ref")
      if(current_input_ref !== null){
        current_input_ref.focus()
      }
    }),

    set_selected_profile: flow(function* (profile = null) {
      Logger.log("Chat:set_selected_profile", profile)
      self.selected_profile = profile
    }),

	}))
  .views(self => ({
    
    show_directory(){
      return self.directory.length > 0 && self.directory_active
    },
    
    show_qa_chat(){
      return self.channels.find(c => c.id === "q&a" && c.is_published) && self.channels.filter(c => c.type === "q&a").length > 0
    },
    
    show_event_chat(){
      return self.channels.length && self.channels.find(c => c.id === "event" && c.is_published)
    },

    show_direct_messages() {
      return this.channel_by_id("direct") && this.channel_by_id("direct").is_published
    },
    
    channel_by_id(id){
      return self.channels.length && self.channels.find(c => c.id === id)
    },

    channels_by_type(type){
      return self.channels.length && self.channels.find(c => c.id === type && c.is_published) && self.channels.filter(c => c.type === type)
    },
    
    is_init_loading(){
      return self.is_loading_directory || self.is_loading_chats
    },

    transform_message_data(doc) {
      let data = doc.data()
      data.id = doc.id
      // Check if we have reactions
      if (data.reactions) {
        let reactions = []
        Object.keys(data.reactions).forEach((key) => {
          reactions.push({type: key, count: data.reactions[ key ]})
        })
        data.reactions = reactions
      }
      if (data.created_at) {
        data.created_at = data.created_at.toDate()
      }
      if (data.updated_at) {
        data.updated_at = data.updated_at.toDate()
      }
      if (data.thread_messages) {
        data.thread_messages = data.thread_messages.map(message => {
          return {
            ...message,
            created_at: message.created_at?.toDate(),
            updated_at: message.updated_at?.toDate(),
          }
        })
      }
      return data
    },

    is_current_user_moderator() {
      return self.user?.role === "moderator"
    },

    moderator_q_a_channels() {
      const channels = self.channels_by_type("q&a")?.filter(channel => channel.messages.length > 0)
      return channels?.sort((a, b) => {
        return new Date(b.last_message().created_at) - new Date(a.last_message().created_at)
      })
    },

    filtered_moderator_q_a_channels() {
      const search_term = App.qa_search_term
      const channels = self.channels_by_type("q&a")
      if (search_term !== "" && search_term != null) {
        return channels.filter(channel => {
          return channel.full_name().toLowerCase().includes(search_term.toLowerCase()) ||
            channel.direct_user_name()?.toLowerCase().includes(search_term.toLowerCase()) ||
            channel.wp_uid?.toString().includes(search_term.toLowerCase())
        })
      }
      return channels?.filter(channel => channel.messages.length > 0)?.sort((a, b) => {
        return new Date(b.last_message().created_at) - new Date(a.last_message().created_at)
      })
    },

    filtered_directory() {
      const search_term = App.directory_search_term
      const directory = self.directory.filter(p => p.is_complete)
      if (search_term !== "" && search_term != null) {
        return directory.filter(profile => {
          return profile.full_name().toLowerCase().includes(search_term.toLowerCase()) ||
            profile.company_name?.toLowerCase().includes(search_term.toLowerCase()) ||
            profile.role?.toLowerCase().includes(search_term.toLowerCase())
        })
      }
      return directory
    },

    direct_messages_channels() {
      const channels = self.channels_by_type("direct")?.filter(channel => channel.messages.length > 0)
      return channels?.sort((a, b) => {
        return new Date(b.last_message().created_at) - new Date(a.last_message().created_at)
      })
    },

    all_direct_messages_channels() {
      return self.channels_by_type("direct")
    },
    
  }))
	.create();

export default Chat;
