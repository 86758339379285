import React from 'react';
import { observer } from 'mobx-react';
import App from './../stores/App'
import Chat from './../stores/Chat'
import ChatModule from './Chat'

const AppModule = observer(
	class AppModule extends React.Component {
	
		componentDidMount = () => {
			App.hydrate(this.props.element)
		}
		
		render() {
      return(
        <ChatModule />
      )
		}
    
    __dev_comp = () => {
      return (
			  <div className="clip__chat clip__chat--dev">
			    <div>
				    <p>Did pass Preflight and can show?:</p>
				    <p><b>{App.can_show_clip().toString()}</b></p>
			    </div>
			    <div>
				    <p>Show directory?:</p>
				    <p><b>{Chat.show_directory().toString()} ({Chat.directory.length})</b></p>
			    </div>
			    <div>
				    <p>Show event?:</p>
				    <p><b>{Chat.show_event_chat().toString()}</b></p>
			    </div>
			  </div>
		  )
    }
	}
)
export default AppModule