import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import Chat from './../stores/Chat'
import Input from './../components/input'
import Loading from './Loading'
import locale from '../locale/locale';
const Messages = lazy(() => import('./../components/messages'));

const QAChatModule = observer(
	class QAChatModule extends React.Component {
		
		render() {
			const channel = !Chat.is_current_user_moderator() ? Chat.channels_by_type("q&a")[ 0 ] : null
			if (channel) {
				return(
					<>
						<Suspense fallback={<Loading subtitle={locale.getting_messages} />}>
							<Messages channel={channel} />
						</Suspense>
						<Input channel={channel} />
					</>
				)
			}
      return <Loading title={locale.whoops} subtitle={locale.not_found} />
		}
    
	}
)
export default QAChatModule