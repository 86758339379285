import React from 'react';
import { observer } from 'mobx-react';
import App from './../stores/App'
import Chat from './../stores/Chat'
import Nav from '../components/nav';
import EventChat from './Event';
import Directory from './Directory';
import Loading from './Loading';
import QAChat from './QA'
import QAModeratorChat from './QAModerator'

const ChatModule = observer(
	class ChatModule extends React.Component {
    
    _render_module = () => {
      if(Chat.is_init_loading()){
        return <Loading />
      }
      switch(App.selected_tab){
        case "directory":
          return <Directory />
        case "event":
          return <EventChat />
        case "q&a":
          return Chat.is_current_user_moderator() ? <QAModeratorChat /> : <QAChat />
        default:
        return null
      }
    }
		
		render() {
      return(
        <>
          <Nav />
          {this._render_module()}
        </>
      )
		}
    
	}
)
export default ChatModule