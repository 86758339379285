/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './../Logger';
import Chat from '../Chat';
import Message from './Message';
import XPCApi, { ERROR, MESSAGE_DELETED, MESSAGE_SENT } from '../../api/XPCApi';
import App from '../App'

const Channel = types
	.model('Channel', {
    id: types.identifier,
    type: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    is_published: types.maybeNull(types.boolean),
    messages: types.optional(types.array(Message), []),
    text: types.optional(types.string, ""),
    is_sending_message: types.optional(types.boolean, false),
    is_typing_reply: types.optional(types.boolean, false),
    reply_message: types.maybeNull(types.reference(Message)),
    is_sending_reaction: types.optional(types.boolean, false),
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    profile_photo_url: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    wp_uid: types.maybeNull(types.number),
    wp_uids: types.maybeNull(types.array(types.model("User Ids", {
      id: types.maybeNull(types.number),
    }))),
    has_unread_messages: types.optional(types.boolean, false),
	})
  .actions(self => ({
    
    afterCreate: flow(function* () {
      Logger.log("Channel:afterCreate", self.id)
      if (self.is_published == null || self.is_published){
        Chat.init_chat_by_model(self)
      }
		}),
    
    handle_message: flow(function* (message) {
			Logger.log("Channel:handle_message", self.id, message)
      self.messages.push(message)
		}),
    
    handle_message_updates: flow(function* (updates) {
      Logger.log("Channel:handle_message_updates", self.id, updates)
      let should_scroll = true
      updates.forEach((change) => {
        if(change.type !== "modified" && change.type !== "added"){
          return
        }
        const doc = change.doc
        let message_data = Chat.transform_message_data(doc)
        Logger.log("Channel:handle_message_updates:doc", message_data)
        // Let's find an existing message
        let existing_message = null
        if (message_data.local_id) {
          existing_message = self.messages.find(message => message.is_local && message.id === message_data.local_id.toString())
        }
        if (!existing_message) {
          existing_message = self.messages.find(m => m.id === doc.id)
        }
        if(existing_message){
          // We probably updated something here...
          if (message_data.is_deleted) {
            self.messages.remove(existing_message)
          }
          else {
            if (existing_message.is_local) {
              message_data.external_id = message_data.id
              message_data.id = existing_message.local_id.toString()
              message_data.is_local = existing_message.is_local
            }
            Logger.log("Channel:handle_message_updates:existing_message", existing_message.local_id, message_data)
            existing_message.update_message(message_data)
          }
        }
        else if(!message_data.is_deleted){ 
          self.messages.push(message_data)
        }
        should_scroll = !message_data.is_deleted
        if (change.type === "added") {
          App.update_notifications(self)
        }
      })
      if (!self.is_sending_reaction && should_scroll) {
        Chat.scroll_current_message_list_ref()
      }
      self.is_sending_reaction = false
		}),
    
    handle_text_input: flow(function* (text) {
			Logger.log("Channel:handle_text_input", self.id, text)
      self.text = text
    }),
    
    handle_emoji_input: flow(function* (emoji_object) {
      Logger.log("Channel:handle_emoji_input", emoji_object)
      self.text += emoji_object.emoji
		}),
    
    send_message_text: flow(function* () {
			Logger.log("Channel:send_message_text", self.id)
      self.is_sending_message = true
      if (self.text !== "") {
        const local_id = Date.now()
        const message = Message.create({
          id: local_id.toString(),
          is_local: true,
          text: self.text,
          is_sending: true,
          wp_uid: Chat.user.wp_uid,
          first_name: Chat.user.first_name,
          last_name: Chat.user.last_name,
          role: Chat.user.role,
          local_id: local_id
        })
        const text = self.text
        const is_typing_reply = self.is_typing_reply
        const reply_message = self.reply_message
        self.messages.push(message)
        Chat.scroll_current_message_list_ref()
        self.text = ""
        self.is_typing_reply = false
        self.reply_message = null
        self.is_sending_message = false
        const response = yield XPCApi.send_message(text, self.id !== "event" ? self.id : null, reply_message != null ? reply_message.external_id ? reply_message.external_id : reply_message.id : null, local_id)
        if (response !== ERROR && response === MESSAGE_SENT) {
          return true
        }
        else {
          alert("Error sending message. Try again...")
          self.messages.remove(message)
          self.text = text
          self.is_typing_reply = is_typing_reply
          self.reply_message = reply_message
        }
      }
      self.is_sending_message = false
      return false
    }),

    send_message_reaction: flow(function* (type, message_id) {
      Logger.log("Channel:send_message_reaction", self.id, type, message_id)
      if (type !== "" && message_id != null && !self.is_sending_reaction) {
        self.is_sending_reaction = true
        const status = yield XPCApi.send_reaction(type, message_id, self.id !== "event" ? self.id : null)
        if (status === MESSAGE_SENT) {
          return true
        }
        return false
      }
    }),

    activate_reply_message: flow(function* (message) {
      Logger.log("Channel:activate_reply_message", self.id, message)
      self.is_typing_reply = true
      self.reply_message = message
      Chat.focus_current_input_ref()
    }),

    cancel_reply_message: flow(function* () {
      Logger.log("Channel:cancel_reply_message", self.id)
      self.is_typing_reply = false
      self.reply_message = null
    }),

    delete_message: flow(function* (message) {
      Logger.log("Channel:delete_message", message.external_id ? message.external_id : message.id)
      const should_delete = window.confirm("Are you sure you want to delete this message?")
      if (should_delete) {
        message.mark_deleted()
        const deleted = yield XPCApi.delete_message(message.external_id ? message.external_id : message.id, self.id !== "event" ? self.id : null)
        if(deleted !== MESSAGE_DELETED){
          message.mark_deleted(false)
        }
      }
    }),
    
    toggle_unread: flow(function* (has_unread = false) {
      Logger.log("Channel:show_unread", self.id)
      self.has_unread_messages = has_unread
    }),
    
  }))
  .views(self => ({

    full_name(){
      return `${self.first_name} ${self.last_name}`
    },
    
    initial(){
      return self.first_name[0]
    },

    last_message() {
      return self.messages.slice(-1)[0]
    },

    direct_user() {
      const user_id = self.wp_uids?.find(u => u.id !== Chat.user.wp_uid)
      return Chat.directory.find(u => u.wp_uid === user_id.id)
    },

    direct_user_name() {
      const user_id = self.wp_uids?.find(u => u.id !== Chat.user.wp_uid)
      if (user_id) {
        Logger.log("Channel:direct_user_name:user_id", user_id)
        const user = Chat.directory.find(u => u.wp_uid === user_id.id)
        if (user) {
          return user.full_name()
        }
        return "Unknown"
      }
      return "Unknown"
    },

    is_dm_with_user_id(user_id){
      return self.wp_uids?.find(u => u.id === user_id)
    }

  }))
export default Channel;
