import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import Chat from './../stores/Chat'
import Loading from './Loading'
import App from '../stores/App'
import Input from '../components/input'
import locale from '../locale/locale';
const Channels = lazy(() => import('./../components/channels'));
const Messages = lazy(() => import('./../components/messages'));

const QAModeratorChatModule = observer(
	class QAModeratorChatModule extends React.Component {
		
		render() {
			if (App.selected_channel) {
				const channel = Chat.channel_by_id(App.selected_channel)
				if (channel) {
					return(
						<>
							<div className="clip__chat--nav-secondary">
								<button onClick={() => App.set_selected_channel(null)}>
									<svg xmlns="http://www.w3.org/2000/svg" style={{ width: 20, height: 20 }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
									</svg>
									{locale.go_back_to_qas}
								</button>
							</div>
							<Suspense fallback={<Loading subtitle={locale.getting_messages} />}>
								<Messages channel={channel} />
							</Suspense>
							<Input channel={channel} />
						</>
					)
				}
				else {
					return <Loading title={locale.whoops} subtitle={locale.not_found} />
				}
			}
			const channels = Chat.is_current_user_moderator() ? Chat.moderator_q_a_channels() : null
			if (channels && channels.length > 0) {
				return (
					<Suspense fallback={<Loading subtitle={locale.getting_channels} />}>
						<div className="clip__chat--search">
              <input value={App.qa_search_term} onChange={(e) => App.set_qa_search_term(e.target.value)} type="text" placeholder={locale.search_channels} />
            </div>
						<Channels
							channels={App.qa_search_term !== "" && App.qa_search_term.length > 0 ? Chat.filtered_moderator_q_a_channels() : channels}
							is_searching={App.qa_search_term !== "" && App.qa_search_term.length > 0}
						/>
					</Suspense>
				) 
			}
      return <Loading title={locale.awaiting_messages} subtitle={locale.nothing_to_see} />
		}
    
	}
)
export default QAModeratorChatModule