/*eslint require-yield: "off"*/
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { types, applySnapshot, flow } from 'mobx-state-tree';
import Chat from '../Chat'
import ThreadMessage from './ThreadMessage'
//import Logger from './../Logger';
dayjs.extend(relativeTime)

const Message = types
	.model('Message', {
		id: types.identifier,
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    profile_photo_url: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    wp_uid: types.maybeNull(types.number),
    reactions: types.maybeNull(types.array(types.model('Reaction', {
      type: types.maybeNull(types.string),
      count: types.maybeNull(types.number)
    }))),
    created_at: types.maybeNull(types.Date),
    updated_at: types.maybeNull(types.Date),
    thread_messages: types.optional(types.array(ThreadMessage), []),
    is_deleted: types.optional(types.boolean, false),
    local_id: types.maybeNull(types.number),
    is_local: types.optional(types.boolean, false),
    is_sending: types.optional(types.boolean, false),
    external_id: types.maybeNull(types.string),
	})
  .actions(self => ({
    
    update_message: flow(function* (object){
      applySnapshot(self, object)
    }),

    mark_deleted: flow(function* (deleted = true) {
      self.is_deleted = deleted
    }),
    
  }))
  .views(self => ({
    
    full_name(){
      return `${self.first_name} ${self.last_name}`
    },
    
    ago(){
      return self.created_at != null ? dayjs().to(self.created_at) : ''
    },

    is_moderator() {
      return self.role === 'moderator'
    },

    has_thread_messages() {
      return self.thread_messages.length > 0
    },

    has_multi_thread_messages() {
      return this.has_thread_messages() && this.thread_messages.length > 1
    },

    show_delete_button() {
      return Chat.is_current_user_moderator() || self.wp_uid === Chat.user?.wp_uid
    }
    
  }))
export default Message;
