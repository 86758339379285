import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import Loading from './Loading'
import App from '../stores/App';
import Chat from '../stores/Chat';
import DirectMessages from '../components/directory/direct-messages'
import locale from '../locale/locale';
const Profiles = lazy(() => import('./../components/directory/profiles'));

const DirectoryModule = observer(
  class DirectoryModule extends React.Component {
    
    _nav = () => {
      return ( 
        <div className={`clip__chat--nav-secondary ${App.selected_direct_message_channel && App.selected_directory_tab === "direct-messages" ? "no-shadow" : ""}`}>
          <button className={App.selected_directory_tab === "directory" ? "selected" : null} onClick={() => App.set_directory_tab("directory")}>
            {locale.directory}
          </button>
          <button className={App.selected_directory_tab === "direct-messages" ? "selected" : null} onClick={() => App.set_directory_tab("direct-messages")}>
            {locale.direct_messages}
          </button>
        </div>
      )
    }

    _render_section = () => {
      if (!Chat.show_direct_messages()) {
        return (
          <Suspense fallback={<Loading subtitle={locale.getting_profiles}/>}>
            <Profiles />
          </Suspense>
        )
      }
      switch (App.selected_directory_tab) {
        case "directory":
          return (
            <Suspense fallback={<Loading subtitle={locale.getting_profiles} />}>
              <Profiles />
            </Suspense>
          )
        case "direct-messages":
          return (
            <Suspense fallback={<Loading subtitle={locale.getting_direct_messages} />}>
              <DirectMessages />
            </Suspense>
          )
        default:
          return null
      }
    }
		
		render() {
      return(
        <>
          {Chat.show_direct_messages() ? this._nav() : null}
          {this._render_section()}
        </>
      )
		}
    
	}
)
export default DirectoryModule