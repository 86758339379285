/*eslint require-yield: "off"*/
import { types, flow, applySnapshot } from 'mobx-state-tree';
import Logger from './../Logger';
import Chat from '../Chat';
import App from '../App'
import XPCApi, { ERROR } from '../../api/XPCApi'

const Profile = types
	.model('Profile', {
		id: types.identifier,
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    profile_photo_url: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    wp_uid: types.maybeNull(types.number),
    company_name: types.maybeNull(types.string),
    is_complete: types.optional(types.boolean, true),
    is_starting_dm: types.optional(types.boolean, false),
	})
  .actions(self => ({
    
    update: flow(function* (data) {
			Logger.log("Profile:update", data)
      applySnapshot(self, data)
    }),
    
    init_direct_message: flow(function* () {
			Logger.log("Profile:init_direct_message")
      self.is_starting_dm = true
      const existing_dm = Chat.all_direct_messages_channels().find(channel => channel.is_dm_with_user_id(self.wp_uid))
      Logger.log("Profile:init_direct_message:existing_dm", existing_dm)
      if (existing_dm) {
        App.set_selected_direct_message_channel(existing_dm.id)
        App.set_directory_tab("direct-messages")
      }
      else {
        // We want to create a new direct message channel
        const dm = yield XPCApi.create_direct_message(self.id)
        Logger.log("Profile:init_direct_message:dm", dm)
        if (dm !== ERROR && dm?.channel_uid != null) {
          setTimeout(() => {
            App.set_selected_direct_message_channel(dm.channel_uid)
            App.set_directory_tab("direct-messages")
          })
        }
      }
      self.is_starting_dm = false
		}),
    
  }))  
  .views(self => ({
    
    full_name(){
      return `${self.first_name} ${self.last_name}`
    },
    
    initial(){
      return self.first_name[0]
    },
    
  }))

export default Profile;
