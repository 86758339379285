import React, { Suspense, lazy } from 'react';
import { observer } from 'mobx-react';
import Chat from '../../stores/Chat'
import App from '../../stores/App'
import Loading from '../../modules/Loading'
import Input from '../../components/input'
import locale from './../../locale/locale'
const Channels = lazy(() => import('./../../components/channels'));
const Messages = lazy(() => import('./../../components/messages'));

const DirectMessages = observer(
	class DirectMessages extends React.Component {
		
		render() {
			if (App.selected_direct_message_channel) {
				const channel = Chat.channel_by_id(App.selected_direct_message_channel)
				if (channel) {
					return(
						<>
							<div className="clip__chat--nav-secondary direct-message">
								<button onClick={() => App.set_selected_direct_message_channel(null)}>
									<svg xmlns="http://www.w3.org/2000/svg" style={{ width: 20, height: 20 }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
									</svg>
									{locale.go_back_to_dms}
								</button>
							</div>
							<Suspense fallback={<Loading subtitle={locale.getting_messages} />}>
								<Messages channel={channel} />
							</Suspense>
							<Input channel={channel} />
						</>
					)
				}
				else {
					return <Loading title={locale.whoops} subtitle={locale.not_found} />
				}
			}
			const channels = Chat.direct_messages_channels()
			if (channels && channels.length > 0) {
				return (
					<Suspense fallback={<Loading subtitle={locale.getting_dms} />}>
						<Channels channels={channels} is_direct_message={true} />
					</Suspense>
				) 
			}
      return <Loading title={locale.awaiting_dms} subtitle={locale.no_dm_messages} />
		}

	}
)
export default DirectMessages